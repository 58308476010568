<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard
            v-bind:height="'100%'"
            class="memo-inbox"
            v-show="
              !axiosLoading && !networkError && singleMemo && singleDisposition
            "
          >
            <header-left
              slot="left-toolbar"
              v-bind:singleMemo="singleMemo"
              v-bind:dispositionMenu="dispositionMenu"
              v-on:menuAction="menuAction"
              v-bind:heading="$t('card.disposition-detail-title')"
            ></header-left>
            <header-right
              slot="right-toolbar"
              v-bind:dispositionStatus="dispositionStatus"
            ></header-right>

            <v-card-text>
              <v-tabs v-model="tabtab" color="blue lighten-2">
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  <v-icon class="mr-2">{{ iconDisposition }}</v-icon>
                  {{ $t("tab.upline-disposition") }}
                </v-tab>
                <v-tab href="#tab-2">
                  <v-icon class="mr-2">{{ iconMail }}</v-icon>
                  {{ $t("tab.memo") }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabtab" touchless>
                <v-tab-item value="tab-1">
                  <disposition-detail-view></disposition-detail-view>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <memo-detail-view></memo-detail-view>
                </v-tab-item>
              </v-tabs-items>
              <hr class="card-footer" v-if="singleMemo && userIsDirPjp" />

              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <v-tooltip bottom v-if="singleMemo">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        class="ma-2"
                        outlined
                        color="primary"
                        @click="downloadPdf()"
                      >
                        <v-icon>{{ iconPrint }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("tooltip.print-preview") }}</span>
                  </v-tooltip>

                  <v-btn
                    v-if="
                      singleMemo &&
                      hasDelegated &&
                      userIsDirPjp &&
                      !isPermitOrBom
                    "
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="setDispositionHistoryDialog(true)"
                  >
                    <v-icon>{{ iconHistory }}</v-icon>
                    {{ $t("button.show-disposition-history") }}
                  </v-btn>

                  <v-menu top left>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            data-hintposition="middle-middle"
                            class="ma-2"
                            outlined
                            color="primary"
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon class="mr-2">{{ iconCog }}</v-icon>
                            {{ $t("button.actions") }}
                          </v-btn>
                        </template>
                        <span>{{ $t("tooltip.select-action") }}</span>
                      </v-tooltip>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, i) in dispositionMenu"
                        :key="i"
                        @click="menuAction(item.command)"
                      >
                        <v-icon class="mr-3">{{ item.icon }}</v-icon>
                        <v-list-item-title>{{
                          $t(item.title)
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
      <v-card
        v-if="
          (networkError || axiosLoading) && (!singleMemo || !singleDisposition)
        "
        class="pa-4"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="table-heading,divider, list-item-avatar-two-line, article: heading, paragraph, divider, actions"
        ></v-skeleton-loader>
      </v-card>
    </v-flex>

    <disposition-modal-simple
      :dispositionParentId="dispositionId"
      :memoId="memoId"
    />
    <broadcast-modal :memoId="memoId" :isSecretMemo="isSecretMemo" />
    <disposition-history-modal
      :memoId="memoId"
      :dispositionId="dispositionHistoryId"
    />
    <memo-validation-modal :singleMemo="singleMemo"></memo-validation-modal>

    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-bind:submitOptions="submitOptions"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
    ></insert-comment>

    <vue-html2pdf
      :show-layout="controlValue.showLayout"
      :float-layout="controlValue.floatLayout"
      :enable-download="controlValue.enableDownload"
      :preview-modal="controlValue.previewModal"
      :filename="controlValue.filename"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight"
      :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat"
      :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth"
      :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <memo-print-preview
        @domRendered="domRendered()"
        v-bind:singleMemo="singleMemo"
        v-bind:recipientSide="true"
        slot="pdf-content"
      />
    </vue-html2pdf>
    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";

import dispositionModalSimple from "@/components/commonComponents/DispositionModalSimple";
import dispositionHistoryModal from "@/components/commonComponents/DispositionHistoryModal";
import broadcastModal from "@/components/commonComponents/BroadcastModal";
import memoValidationModal from "@/components/commonComponents/MemoValidationModal";
import memoDetailView from "./MemoDetailView";
import dispositionDetailView from "./DispositionDetailView";
import toolbarMenu from "@/constants/toolbarmenu";
import insertComment from "@/components/commonComponents/InsertComment.vue";

import MemoPrintPreview from "@/components/commonComponents/MemoPrintPreview.vue";
import VueHtml2pdf from "vue-html2pdf";
import { mapFields } from "vuex-map-fields";
import { http } from "@/http/http";

import {
  mdiAttachment,
  mdiTransfer,
  mdiTimelapse,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiAlertCircleOutline,
  mdiInformationOutline,
  mdiHistory,
  mdiAccountSwitch,
  mdiEmailOpenOutline,
  mdiPrinter,
  mdiMessageReplyTextOutline,
  mdiCogTransfer,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    memoDetailView,
    dispositionDetailView,
    dispositionModalSimple,
    dispositionHistoryModal,
    broadcastModal,
    memoValidationModal,
    VueHtml2pdf,
    MemoPrintPreview,
    insertComment,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiTimelapse,
    iconTransfer: mdiTransfer,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconAlert: mdiAlertCircleOutline,
    iconInfo: mdiInformationOutline,
    iconDisposition: mdiAccountSwitch,
    iconMail: mdiEmailOpenOutline,
    iconHistory: mdiHistory,
    iconCog: mdiCogTransfer,
    iconPrint: mdiPrinter,
    iconFeedback: mdiMessageReplyTextOutline,
    tabtab: null,
    menus: toolbarMenu,
    overlay: false,
    showCommentDialog: false,
    submitOptions: undefined,
    comment: undefined,
    filterParams: {
      sortBy: "created_at",
      sortByDesc: "desc",
      perPage: 50,
      currentPage: 1,
      filter: "",
      from: 1,
      to: 50,
      lastPage: 1,
    },
  }),
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapFields("pdfgenerator", ["controlValue"]),
    ...mapState("disposition", [
      "singleDisposition",
      "singleDelegation",
      "singleMemo",
      "axiosLoading",
      "dispositionHistory",
    ]),
    ...mapState("user", ["currentUser"]),
    dispositionStatus() {
      let result = {
        status: "done",
        class: "success",
        icon: this.iconFeedback,
      };
      if (!this.singleDisposition) return result;

      if (this.currentUser.usertype_elemen === "KRANI") {
        if (this.hasFeedback) {
          result = {
            status: "done",
            class: "success",
            icon: this.iconFeedback,
          };
        } else {
          result = { status: "pending", class: "warning", icon: this.iconCog };
        }
      } else {
        if (!this.hasFeedback && !this.hasDelegated) {
          result = {
            status: "pending",
            class: "warning",
            icon: this.iconCog,
          };
        } else if (this.hasFeedback && !this.hasDelegated) {
          result = {
            status: "done",
            class: "success",
            icon: this.iconFeedback,
          };
        } else {
          result = {
            status: "delegated",
            class: "success",
            icon: this.iconTransfer,
          };
        }
      }
      return result;
    },
    dispositionMenu() {
      if (!this.singleDisposition) return [];
      // let myTask = this.singleDisposition.recipients.map(({ nik }) => nik);
      if (this.userIsSekreKrani) {
        if (this.hasFeedback) {
          return this.menus.dispositionDetailKHFMenu;
        } else {
          return this.menus.dispositionDetailKNFMenu;
        }
      } else {
        if (!this.hasFeedback && !this.hasDelegated) {
          return this.menus.dispositionDetailNDTMenu;
        } else if (this.hasFeedback && !this.hasDelegated) {
          if (this.userIsDirPjp) {
            return this.menus.dispositionDetailKHFPjpMenu;
          } else {
            return this.menus.dispositionDetailKHFMenu;
          }
        } else {
          if (this.userIsDirPjp || this.userIsSekrtaris) {
            if (this.singleMemo.memo.tipe === 'izin_prinsip' ) return this.menus.dispositionDetailPermitHDTPjpMenu;
            return this.menus.dispositionDetailHDTPjpMenu;
          } else {
            return this.menus.dispositionDetailHDTMenu;

          }
        }
      }
    },
    userIsDirPjp() {
      if (!this.currentUser) return false;
      return ["DIREKTUR", "PJP", "PJP_KANPUS"].includes(
        this.currentUser.usertype_elemen
      );
    },
    userIsSekreKrani() {
      if (!this.currentUser) return true;
      return ["SEKRETARIS", "KRANI","SEKRETARIS_KRANI"].includes(this.currentUser.usertype_elemen);
    },
    userIsSekrtaris(){
      if (!this.currentUser) return false;
      return ["SEKRETARIS", "SEKRETARIS_DIREKSI",'SEKRETARIS_DIRSEVP'].includes(this.currentUser.usertype_elemen);
    },
    isPermitOrBom() {
      if (!this.singleMemo) return false;
      return (
        parseInt(this.singleMemo.memo.is_izin_prinsip) === 1 ||
        parseInt(this.singleMemo.memo.is_bom) === 1
      );
    },
    hasDelegated() {
      if (!this.singleDisposition) return true;
      return parseInt(this.singleDisposition.has_delegation) === 1;
    },
    hasFeedback() {
      if (!this.singleDisposition) return false;
      return parseInt(this.singleDisposition.has_feedback) === 1
    },
    myTask() {
      if (!this.singleDisposition) return undefined;
      let that = this;
      let result = this.singleDisposition.recipients.filter(function (person) {
        return person.nik == that.currentUser.nik;
      });
      return result;
    },
    memoId() {
      if (!this.singleMemo) return null;
      return this.singleMemo.memo.id_hash;
    },
    isSecretMemo() {
      if (!this.singleMemo) return false;
      return this.singleMemo.memo.is_memo_rahasia == 1;
    },
    dispositionId() {
      if (!this.singleDisposition) return null;
      return this.singleDisposition.id_hash;
    },
    dispositionHistoryId() {
      if (!this.singleMemo) return undefined;
      return this.singleMemo.disposition;
    },
    htmlToPdfOptions() {
      return {
        margin: 2,
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
        filename: this.singleMemo
          ? this.strCut(this.singleMemo.memo.hal, 100) + ".pdf"
          : "undefined.pdf",
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "cm",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
  },
  watch: {
    singleMemo(val) {
      if (!val) return;
      this.loadSignerHistory(this.singleMemo.memo.id_hash);
      // this.loadInboxHistory(this.singleMemo.memo.id_hash);
      this.getDispositionTrack({ memoId: this.singleMemo.memo.id_hash });
      // this.getDispositionFeedbacks({memo_id : this.memoId, disposition_id: this.dispositionId})
    },
  },
  created() {
    this.reloadThis();
    // this.loadDispositionDetail({
    //   bgProcess: false,
    //   dataType: "mydelegation",
    //   memo_id: this.$route.params.id,
    // });
  },

  mounted() {
    setTimeout(() => {
      this.tabtab = "tab-0";
    }, 1000);
  },
  methods: {
    ...mapActions("disposition", [
      "getDispositionDetail",
      "sendFeedback",
      "getDispositionTrack",
      "getDispositionFeedbacks",
    ]),
    ...mapActions("inbox", [
      "setDispositionHistoryDialog",
      "setDispositionSimpleDialog",
      "setMemoValidationDialog",
      "setBroadcastDialog",
      "loadSignerHistory",
      "loadInboxHistory",
      "setDispositionHistoryDialog",
    ]),
    menuAction(params) {
      console.log('opening history')
      switch (params) {
        case "disposition":
          if (parseInt(this.singleDisposition.has_delegation) === 1) return;
          this.setDispositionSimpleDialog(true);
          break;
        case "disposition_history":
          this.setDispositionHistoryDialog(true);
          break;
        case "reload":
          this.reloadThis();
          break;
        case "validate":
          this.setMemoValidationDialog(true);
          break;
        case "feedback":
          this.submitFeedback();
          break;
        case "broadcast":
          this.broadcastMemo();
          break;
        case "share_link":
          this.$copyText(
            `https://elemen4.ptpn4.co.id/link/${this.singleDisposition.memo_id_hash}`
          ).then(() => {
            this.$dialog.notify.info(
              this.$t("msgbox.you-just-copied-the-memo-message-to-clipboard"),
              {
                position: "top-left",
                timeout: 5000,
              }
            );
          });
          break;
        case "print_preview":
          this.downloadPdf();
          break;
        case "print_dispo_form":
          this.downloadDispoForm({
            memoId: this.singleMemo.memo.id_hash,
            dispositionId: this.dispositionId,
          });
          break;

        default:
          break;
      }
    },
    reloadThis() {
      this.getDispositionDetail({
        dispositionId: this.$route.params.id,
        memoId: this.$route.params.id,
      });
    },
    broadcastMemo() {
      if (parseInt(this.singleMemo.memo.is_izin_prinsip) === 1) {
        return this.$dialog.error({
          text: this.$t("msgbox.cannot broadcast permit memo"),
          title: this.$t("msgbox.broadcast-blocked"),
          persistent: true,
          actions: [{ text: this.$t("button.ok"), color: "blue", key: false }],
        });
      }
      if (this.userIsSekreKrani) {
        return this.$dialog.error({
          text: this.$t("msgbox.krani-sekretaris-cannot-broadcast-memo"),
          title: this.$t("msgbox.broadcast-blocked"),
          persistent: true,
          actions: [{ text: this.$t("button.ok"), color: "blue", key: false }],
        });
      }
      if (this.isSecretMemo) {
        this.$dialog.warning({
          text: this.$t("msgbox.beware-to-broadcast-secret-memo"),
          title: this.$t("msgbox.secret-memo"),
          persistent: true,
          actions: [{ text: this.$t("button.ok"), color: "blue", key: false }],
        });
      }
      this.setBroadcastDialog(true);
    },
    async downloadPdf() {
      window.scrollTo(0, 0);
      this.updateOverlay(true);
      try {
        const response = await http.get(
          `/memo/mydisposition/${this.singleMemo.memo.id_hash}/getpdf`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            responseType: "blob",
          }
        )
        var file = new Blob([response], { type: 'application/pdf' });
        var url = URL.createObjectURL(file);
        window.open(url, '', 'width=800,height=768');
      }
      catch (error) {
        console.error(error);
        this.updateOverlay(false);
      }
      finally {
        this.updateOverlay(false);
      }
    },
    async downloadDispoForm(params) {
      return new Promise((resolve) => {
        http
          .get(
            `/memo/mydisposition/${params.memoId}/getform/${params.dispositionId}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                mode: "no-cors",
              },
              responseType: "blob",
            }
          )
          .then((response) => {
            // let fileURL = window.URL.createObjectURL(new Blob([response]));
            // let fileLink = document.createElement("a");

            // fileLink.href = fileURL;
            // fileLink.setAttribute("download", "disposition_"+params.dispositionId+".pdf");
            // document.body.appendChild(fileLink);
            // fileLink.click();
          var file = new Blob([response], { type: 'application/pdf' });
          var url = URL.createObjectURL(file);
          window.open(url, '', 'width=800,height=768');
          });
        return resolve;
      });
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert("pdf-quality value should only be 0 - 2");
        this.controlValue.pdfQuality = 2;
        return false;
      }
      if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;
        return false;
      }
      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];
      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = "a4";
        return false;
      }
      if (!this.controlValue.pdfOrientation) {
        alert("pdf-orientation value cannot be empty");
        this.controlValue.pdfOrientation = "portrait";
        return false;
      }
      if (!this.controlValue.pdfContentWidth) {
        alert("pdf-content-width value cannot be empty");
        this.controlValue.pdfContentWidth = "800px";
        return false;
      }
      return true;
    },
    onProgress(progress) {
      this.progress = progress;
      console.info(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      //param{ html2pdf, options, pdfContent }
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },
    hasDownloaded() {
      this.pdfDownloaded = true;
      setTimeout(() => {
        this.updateOverlay(false);
      }, 300);
    },
    domRendered() {
      this.contentRendered = true;
    },
    onBlobGenerate() {},
    updateOverlay(val) {
      this.overlay = val;
    },
    submitFeedback() {
      if (!this.comment) {
        this.submitOptions = {
          btnText: "button.send-feedback",
          btnIcon: this.iconFeedback,
          commentDesc: this.$t("text.please-fill-feedback-comment"),
        };
        return (this.showCommentDialog = true);
      }
      let formData = {
        feedback: this.comment,
      };
      this.sendFeedback({
        dispositionId: this.singleDisposition.id_hash,
        formData: formData,
      });
      this.$nextTick(() => {
        this.comment = undefined;
        //reload this page data
      }, 500);
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    commentFilled(val) {
      if (!val) return;
      this.comment = val;
      this.submitFeedback();
    },
  },
};
</script>
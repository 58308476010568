<template>
  <v-dialog v-model="dispositionSimpleDialog" persistent max-width="850">
    <v-card v-if="dispositionRecipients">
      <v-card-title class="headline">
        {{ $t("card.memo-disposition") }}
      </v-card-title>
      <v-card-text
        v-if="dispositionRecipients && dispositionRecipients.length > 0"
        >{{ $t("label.to-whom-this-memo-will-be-dispositioned") }}</v-card-text
      >
      <form>
        <v-card-text class="pt-0">
          <v-row>
            <v-card-text
              class="pb-0"
              v-if="
                $v.form.selectedPersons.$error &&
                !$v.form.selectedPersons.required
              "
            >
              <v-alert
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left"
              >
                {{ $t("error.disposition-recipient-must-be-choosen") }}
              </v-alert>
            </v-card-text>
              <v-card-text
                class="text-center red--text"
                v-if="
                  !dispositionRecipients || dispositionRecipients.length < 1
                "
              >
                {{ $t("text.looks-like-you-have-no-subordinates") }}
              </v-card-text>

            <v-col cols="12" sm="12" md="6">
              <div class="pl-2">
                <label
                  class="avoore-checkbox path d-block"
                  v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  v-for="item in firstColumnRecipients"
                  :key="item.code"
                  :for="item.code"
                >
                  <input
                    type="checkbox"
                    class="d-inline-flex"
                    v-model="form.selectedPersons"
                    :value="item.nik"
                    :id="item.code"
                  />
                  <svg viewBox="0 0 21 21">
                    <path
                      d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                    ></path>
                  </svg>
                  <span :for="item.code" class="checkbox-title">{{
                    item.nama
                  }}</span>
                  <span :for="item.code" class="checkbox-subtitle" v-show="currentUser.usertype_elemen == 'DIREKTUR'">{{item.jabatan}}</span>
                </label>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <div class="pl-2">
                <label
                  class="avoore-checkbox path d-block"
                  v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  v-for="item in secondColumnRecipients"
                  :key="item.code"
                >
                  <input
                    type="checkbox"
                    class="d-inline-flex"
                    v-model="form.selectedPersons"
                    :value="item.nik"
                    :id="item.code"
                  />
                  <svg viewBox="0 0 21 21">
                    <path
                      d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                    ></path>
                  </svg>
                  <span :for="item.code" class="checkbox-title">{{
                    item.nama
                  }}</span>
                   <span :for="item.code" class="checkbox-subtitle" v-show="currentUser.usertype_elemen == 'DIREKTUR'">{{item.jabatan}}</span>
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row class="pl-2">
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                class="font-size-default"
                rows="3"
                v-model="form.comment"
                name="comment"
                :label="$t('label.comment')"
                value=""
                hint="Komentar tambahan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text
          class="pb-2"
          v-if="$v.form.chkAgree.$error && !$v.form.chkAgree.required"
        >
          <v-alert
            dense
            type="error"
            :icon="iconAlert"
            class="modal-alert v-alert--narrow v-alert--bubble bottom--right bottom--right-20-percent"
          >
            {{ $t("error.you-must-agree-to-process") }}
          </v-alert>
        </v-card-text>

        <v-card-text class="d-flex justify-end mr-2 pt-0">
          <label
            class="avoore-checkbox path d-block"
            v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
          >
            <input
              type="checkbox"
              class="d-inline-flex"
              v-model="form.chkAgree"
              :value="true"
              id="chk-agree-simple"
            />
            <svg viewBox="0 0 21 21">
              <path
                d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
              ></path>
            </svg>
            <span for="chk-agree-simple" class="checkbox-title">{{
              $t("label.agree-to-process")
            }}</span>
          </label>
        </v-card-text>
      </form>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>

        <v-btn
          outlined
          color="red darken-1"
          @click="dispositionSimpleDialog = false"
        >
          <v-icon class="mr-2">{{ iconClose }}</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-btn outlined color="primary darken-1" @click="submit">
          <v-icon class="mr-2">{{ iconDisposition }}</v-icon>
          {{ $t("button.do-disposition") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!dispositionRecipients">
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import {
  mdiAlertCircleOutline,
  mdiClose,
  mdiTransfer,
} from "@mdi/js";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");

export default {
  name: "disposition-modal",
  props: {
    memoId: String ,
    dispositionParentId: String
  },
  data: () => ({
    iconDisposition: mdiTransfer,
    iconClose: mdiClose,
    form: {
      selectedPersons: [],
      comment: "Silahkan diproses",
      urgensi: 1,
      chkAgree: 0,
    },
    dispositionAlert: true,
    iconAlert: mdiAlertCircleOutline,
  }),
  mixins: [validationMixin],
  validations: {
    form: {
      selectedPersons: {
        required,
      },
      chkAgree: {
        required(val) {
          return val;
        },
      },
    },
  },
  created() {
    // this.getDataResource({ source: "disposition-recipients", bgProcess: true });
  },
  computed: {
     ...mapState("user",["currentUser"]),
    ...mapGetters("resources", [
      "dispositionMessages",
      "dispositionRecipients",
    ]),
    dispositionSimpleDialog: {
      get() {
        return this.$store.state.inbox.dispositionSimpleDialog;
      },
      set(val) {
        this.$store.commit("inbox/SET_DISPOSITION_SIMPLE_DIALOG", val);
      },
    },
    perColumnRecipientsRow() {
      if (!this.dispositionRecipients) return 5;
      if (this.isEventOrOdd(this.dispositionRecipients.length) == "even") {
        return this.dispositionRecipients.length / 2;
      }
      return this.dispositionRecipients.length / 2 + 1;
    },

    firstColumnRecipients() {
      if (!this.dispositionRecipients) return undefined;
      return this.dispositionRecipients.slice(0, this.perColumnRecipientsRow);
    },
    secondColumnRecipients() {
      if (!this.dispositionRecipients) return undefined;
      return this.dispositionRecipients.slice(
        this.perColumnRecipientsRow,
        this.dispositionRecipients.length
      );
    },
  },
  watch: {
    dispositionSimpleDialog(val) {
      if (!val) return;
      this.$v.$reset();
      this.form.selectedPersons = [];
      this.form.chkAgree = false;
      this.setDispositionRecipients(null);
      this.getDataResource({
        source: "disposition-recipients",
        bgProcess: true,
      });
    },
  },
  methods: {
    ...mapActions("resources", ["getDataResource", "setDispositionRecipients"]),
    ...mapActions("disposition", ["submitMyDisposition"]),
    submit() {
      this.$v.$touch();
      setTimeout(() => {
        if (this.$v.$invalid) return;

        let formData = {
          comment: this.form.comment,
          urgensi: this.form.urgensi,
          parent_disposition_id: this.dispositionParentId,
          recipients: this.form.selectedPersons.toString()
        };

        /*
        kode di bawah ada param memoId
        memoId pada laman disposition adalah DispositionId
        pada laman inbox = memoId sebenarnya.
        karena modal ini berbagi pakai jadinya disulap dalam param memoId semuanya
        */
        if (!this.memoId) return console.error("Tidak ada Id Memo");
        this.submitMyDisposition({
          formData: formData,
          memoId: this.memoId,
          dispositionId: this.dispositionParentId,
          action: "dispositions",
        });

        this.$nextTick(() => {
          this.dispositionSimpleDialog = false;
        });
      }, 300);
    },
  },
};
</script>
<style>
.modal-alert {
  margin-bottom: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
</style>
<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <v-toolbar-title>{{ heading }}</v-toolbar-title>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-4 mr-2"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="backToDispositionList"
        >
          <v-icon>{{ iconBack }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.back-to-disposition-list") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('reload')"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>
    <v-tooltip bottom v-if="hasDelegated && !hasFeedback && !isPermitOrBom">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="setDispositionHistoryDialog(true)"
        >
          <v-icon>{{ iconHistory }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.memo-disposition-history") }}</span>
    </v-tooltip>

    <v-tooltip
      bottom
      v-if="!hasDelegated && !userIsDirPjp && !userIsSekretaris &&  !hasFeedback"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="
            replyMemo(
              singleMemo.memo.id_hash,
              singleMemo.memo.nomor,
              singleMemo.memo.hal
            )
          "
        >
          <v-icon>{{ iconReply }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reply-this-memo") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="d-none d-sm-inline ml-2"
          dark
          color="blue lighten-1 text-white"
          v-bind="attrs"
          v-on="on"
          small
        >
          {{ $t("button.do-disposition") }}
        </v-btn>
      </template>
      <span>{{ $t("tooltip.disposition-this-memo") }}</span>
    </v-tooltip>

    <v-menu bottom left>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-2"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconCog }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.other-menu") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in dispositionMenu"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="menuAction(item.command)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {
  mdiCog,
  mdiEmailOutline,
  mdiDatabaseClockOutline,
  mdiBackburger,
  mdiReply,
  mdiAccountArrowRightOutline,
  mdiReload,
} from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";
import { mapActions, mapState } from "vuex";

export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    dispositionMenu : {type : [Object, Array]}
  },
  data: () => ({
    iconMail: mdiEmailOutline,
    iconCog: mdiCog,
    iconBack: mdiBackburger,
    iconReply: mdiReply,
    iconDisposition: mdiAccountArrowRightOutline,
    iconHistory: mdiDatabaseClockOutline,
    iconReload: mdiReload,
    menus: toolbarMenu,
  }),
  computed: {
    ...mapState("disposition", ["singleDisposition", "singleMemo"]),
    ...mapState("user", ["currentUser"]),
    hasDelegated() {
      if (!this.singleDisposition) return true;
      return parseInt(this.singleDisposition.has_delegation) === 1;
    },
    isPermitOrBom(){
      if (!this.singleMemo) return false;
      return (parseInt(this.singleMemo.memo.is_izin_prinsip) === 1 || (parseInt(this.singleMemo.memo.is_bom) === 1))
    },
    userIsDirPjp() {
      if (!this.currentUser) return false;
      return ["DIREKTUR", "PJP", "PJP_KANPUS"].includes(
        this.currentUser.usertype_elemen
      );
    },
    userIsSekretaris() {
      if (!this.currentUser) return true;
      return this.currentUser.usertype_elemen == "SEKRETARIS";
    },
    isKrani(){
      if (!this.currentUser) return true;
      let sekrertarisKrani = ['KRANI','SEKRETARIS_KRANI'];
      return sekrertarisKrani.includes(this.currentUser.usertype_elemen);
    },
    hasFeedback() {
      if (!this.singleDisposition) return false;
      return parseInt(this.singleDisposition.has_feedback) === 1;
    },
    broadcastEnable() {
      //enable/disable tombol broadcast bergantung kepada
      //message disposisi nomor 8 dan 21
      if (!this.singleDisposition || !this.singleDisposition.messages)
        return false;
      let messageIds = this.singleDisposition.messages.map(({ id }) => id);
      if (messageIds.includes(8) || messageIds.includes(21)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("inbox", [
      "setDispositionDialog",
      "setDispositionHistoryDialog",
      "setPrintPreviewDialog",
      "setBroadcastDialog",
      "setMemoValidationDialog",
    ]),
    ...mapActions(["setCreateGeneralMemo"]),
    ...mapActions("draft", ["setMemoToReply", "setEditGeneralMemo"]),
    backToDispositionList() {
      this.$router.push({
        name: "disposition",
      });
    },
    replyMemo(id, nomor, hal) {
      this.setEditGeneralMemo({
        show: false,
        editParams: {
          memo_id: null,
        },
      });
      let kepada = this.singleMemo.pengirim.inisial;

      this.setMemoToReply({ id: id, nomor: nomor, hal: hal, kepada: [kepada] });
      this.setCreateGeneralMemo(true);
    },
    onProgress() {
      this.$dialog.notify.warning(this.$t("msgbox.under-construction"), {
        position: "top-left",
        timeout: 5000,
      });
    },
    menuAction(command) {
      return this.$emit("menuAction", command);
    },
  },
};
</script>
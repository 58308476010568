<template>
  <div v-if="singleMemo">
    <v-card-text class="py-2 px-4">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-list-item
            three-line
            v-if="singleMemo && singleMemo.memo"
            class="table-responsive-container"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar
                  v-on="on"
                  v-bind="attrs"
                  :class="{
                    'sender__avatar-desktop': !$vuetify.breakpoint.smAndDow,
                  }"
                >
                  <img
                    :src="singleMemo.memo.avatar_path"
                    alt="approver"
                    v-if="singleMemo && singleMemo.memo.approver_name"
                  />
                </v-list-item-avatar>
              </template>
              <span>{{ singleMemo.memo.approver_name }}</span>
            </v-tooltip>
            <v-list-item-content class="table-responsive">
              <v-list-item-title class="headline">
                <span style="cursor: auto">
                  {{
                    singleMemo && singleMemo.memo.hal
                      ? $t("label.memo-subject") + ": " + singleMemo.memo.hal
                      : "Hal"
                  }}
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="parseInt(singleMemo.memo.is_izin_prinsip) === 1"
                      href="javascript:void(0)"
                      class="label theme-bg4 text-white f-12"
                      >{{ $t("badge.permit-memo") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.permit-memo") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-show="parseInt(singleMemo.memo.is_memo_direksi) === 1"
                      class="ma-2 theme-bg4"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("badge.directors-memo") }}
                    </v-chip>
                  </template>
                  <span>{{ $t("tooltip.directors-memo") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-show="parseInt(singleMemo.memo.has_delegated) === 1"
                      class="ma-2 theme-bg"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("badge.has-delegated") }}
                    </v-chip>
                  </template>
                  <span>{{ $t("tooltip.memo-has-delegated") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-show="singleMemo.memo_attachments.length > 0"
                      class="att-icon"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ iconAttachment }}
                    </v-icon>
                  </template>
                  <span>{{ $t("tooltip.attachment-exist") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-show="parseInt(singleMemo.memo.is_masuk) === 1"
                      class="att-icon"
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ iconExternal }}
                    </v-icon>
                  </template>
                  <span>{{ $t("tooltip.incoming-letter") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-show="parseInt(singleMemo.memo.urgensi) === 1"
                      class="att-icon"
                      color="warning"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ iconUrgent }}
                    </v-icon>
                  </template>
                  <span>{{ $t("tooltip.urgent") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-show="parseInt(singleMemo.memo.is_rahasia) === 1"
                      class="att-icon"
                      color="warning"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ iconSecret }}
                    </v-icon>
                  </template>
                  <span>{{ $t("tooltip.secret") }}</span>
                </v-tooltip>
              </v-list-item-title>
              <p style="cursor: auto" class="v-list-item__subtitle text--bold">
                {{
                  `${$t("label.memo-number")} : ${
                    singleMemo ? singleMemo.memo.nomor : ""
                  }`
                }}
              </p>
              <v-menu
                bottom
                right
                offset-y
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-list-item-subtitle
                        v-if="
                          singleMemo && parseInt(singleMemo.memo.is_bom) === 0
                        "
                        class="
                          sender-info-button
                          text-bolder
                          mini-shake-infinite
                        "
                        v-on="{ ...tooltip, ...menu }"
                        v-bind="attrs"
                        style="max-width: 600px"
                        >{{
                          `${
                            singleMemo.pengirim.bagian
                              ? singleMemo.pengirim.bagian + "-"
                              : ""
                          } ${singleMemo.pengirim.unit}`
                        }}<v-icon class="">{{
                          iconInfo
                        }}</v-icon></v-list-item-subtitle
                      >

                      <v-list-item-subtitle
                        v-else
                        class="
                          sender-info-button
                          text-bolder
                          mini-shake-infinite
                        "
                        v-on="{ ...tooltip, ...menu }"
                        v-bind="attrs"
                        style="max-width: 600px"
                        >{{
                          `${singleMemo ? singleMemo.pengirim.name : ""} - ${
                            singleMemo ? singleMemo.pengirim.unit : ""
                          }`
                        }}<v-icon class="">{{
                          iconInfo
                        }}</v-icon></v-list-item-subtitle
                      >
                    </template>
                    <span>{{ $t("tooltip.sender-info") }}</span>
                  </v-tooltip>
                </template>

                <div class="popup-card">
                  <table class="sender-info-table">
                    <tr>
                      <td class="desc">{{ $t("label.signed-by") }}</td>
                      <td>:</td>
                      <td style="padding-left: 0.8rem">
                        {{ singleMemo ? singleMemo.memo.approver_name : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="desc">{{ $t("label.revised-by") }}</td>
                      <td>:</td>
                      <td v-if="singleMemoSignerHistory">
                        <ol>
                          <li
                            v-for="item in singleMemoSignerHistory"
                            :key="item.id"
                          >
                            {{ item.nama }}
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-menu>
            </v-list-item-content>

            <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>

            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  {{
                    singleMemo
                      ? $date(singleMemo.memo.signed_at).format("DD MMM YYYY")
                      : ""
                  }}
                </span>
              </template>
              <span>{{
                singleMemo
                  ? $t("tooltip.received-at") +
                    " " +
                    $date(singleMemo.memo.signed_at).fromNow()
                  : ""
              }}</span>
            </v-tooltip>
          </v-list-item>
        </v-col>
        <v-col cols="12">
          <h3 class="mt-2">{{ $t("text.memo-message") }}</h3>
          <froalaView
            v-if="singleMemo && singleMemo.memo"
            v-model="singleMemo.memo.isi"
            class="px-4 py-3"
          ></froalaView>
        </v-col>
        <v-row>
          <v-col cols="12">
            <div
              class="attachment-list mb-2"
              v-if="singleMemo && singleMemo.memo_attachments.length > 0"
            >
              <span class="d-block pl-3 mt-4 text-bolder text-smaller">{{
                $t("label.attachment-list")
              }}</span>
              <ul>
                <li
                  v-for="(item, index) in singleMemo.memo_attachments"
                  v-bind:key="index"
                  v-bind:value="item.id_hash"
                  class="file--list"
                >
                  <a href="javascript:;" class="server--file">
                    <span>{{ index + 1 }}.</span>
                    {{ item.file_name }} - {{ item.file_size | formatSize }}

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          large
                          class="ml-2"
                          color="blue"
                          v-on:click="downloadAttachment(item)"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon>{{ iconDownload }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("tooltip.download-this-file") }}</span>
                    </v-tooltip>
                  </a>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-row>
        <v-row class="mt-2">

        
        <v-col
          cols="12"
          md="6"
          sm="12"
          v-if="singleMemo && singleMemo.penerima"
        >
          <h4 class="mt-3 mb-3">{{ $t("text.memo-recipients") }}</h4>
          <div class="mb-4">

            <v-chip
            v-for="item in singleMemo.penerima"
            :key="item.id"
            class="mr-1 mb-1 recipient__chip"
            >
            {{ item.nama }}
          </v-chip>
        </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          v-bind:class="{ 'cc__border-left': !$vuetify.breakpoint.smAndDown }"
          v-if="
            singleMemo && singleMemo.tembusan && singleMemo.tembusan.length > 0
          "
        >
          <h4 class="mt-3 mb-3">{{ $t("text.memo-carbon-copy") }}</h4>
          <v-chip
            v-for="item in singleMemo.tembusan"
            :key="item.id"
            class="mr-1 mb-1 recipient__chip"
          >
            {{ item.name }}
          </v-chip>
        </v-col>
        </v-row>
    </v-card-text>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { http } from "@/http/http";
import {
  mdiAttachment,
  mdiClockFast,
  mdiCloudDownload,
  mdiInboxArrowDown,
  mdiIncognito,
  mdiInformationOutline,
} from "@mdi/js";

export default {
  name: "memo-view-detail",
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconSecret: mdiIncognito,
    iconInfo: mdiInformationOutline,
    iconDownload: mdiCloudDownload,
    iconExternal: mdiInboxArrowDown,
  }),
  computed: {
    ...mapState("disposition", ["singleMemo"]),
    ...mapState("inbox", ["singleMemoSignerHistory", "singleMemoHistory"]),
  },
  watch: {
    singleMemo(val) {
      if (!val) return;
      this.loadSignerHistory(this.singleMemo.memo.id_hash);
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
  methods: {
    ...mapActions("inbox", ["loadSignerHistory"]),
    getAttachment(url, fileName) {
      const options = {
        responseType: "blob",
      };

      this.setAxiosLoading(true);

      http.get(url, options).then((response) => {
        if (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);

          this.setAxiosLoading(false);
        }
        fileLink.click();
      });
    },
    downloadAttachment(file) {
      if (!file) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);

      http
        .get(`/memo/${file.memo_id}/attachments/${file.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          if (response) {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", file.file_name);
            document.body.appendChild(fileLink);

            this.updateOverlay(false);
            fileLink.click();
          }
        });
    },
    async downloadPdf() {
      window.scrollTo(0, 0);
      this.updateOverlay(true);

      if (this.$vuetify.breakpoint.smAndDown) {
        this.controlValue.enableDownload = true;
        this.controlValue.previewModal = false;
      } else {
        this.controlValue.enableDownload = false;
        this.controlValue.previewModal = true;
      }
      if (!(await this.validateControlValue()))
        return this.updateOverlay(false);
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 200);
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert("pdf-quality value should only be 0 - 2");
        this.controlValue.pdfQuality = 2;
        return false;
      }
      if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;
        return false;
      }
      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];
      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = "a4";
        return false;
      }
      if (!this.controlValue.pdfOrientation) {
        alert("pdf-orientation value cannot be empty");
        this.controlValue.pdfOrientation = "portrait";
        return false;
      }
      if (!this.controlValue.pdfContentWidth) {
        alert("pdf-content-width value cannot be empty");
        this.controlValue.pdfContentWidth = "800px";
        return false;
      }
      return true;
    },
    onProgress(progress) {
      this.progress = progress;
      console.info(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      //param{ html2pdf, options, pdfContent }
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },
    hasDownloaded() {
      this.pdfDownloaded = true;
      this.updateOverlay(false);
    },
    domRendered() {
      this.contentRendered = true;
    },
    onBlobGenerate() {},
    updateOverlay(val) {
      this.overlay = val;
    },
  },
};
</script>